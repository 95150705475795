import ConditionalButton from "../Buttons/ConditionalButton";
import ModalComponent from "../../ModalComponent/ModalComponent";

const ThirdDegreeTabPanel = ({ collection, name, number }) => {
    const firstProject = collection[number].content[0];
    const secondProject = collection[number].content[1];

    const generateProjectList = (projectContent, depth, index) => {
        return projectContent.content.map((project, subIndex) => (
            <div key={subIndex}>
                <ConditionalButton
                    content={project}
                    modalId={`${name}Modal${index}Number${subIndex}`}
                />
                <ModalComponent
                    modalId={`${name}Modal${index}Number${subIndex}`}
                    content={project}
                    folderName={collection[number].folderName}
                    depth={depth}
                />
            </div>
        ));
    };

    return (
        <div
            className="tab-pane fade carousel-img"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{firstProject.name}</div>
            <div>{generateProjectList(firstProject, 2, 0)}</div>
            <div className="sub-titles">{secondProject.name}</div>
            <div>{generateProjectList(secondProject, 2, 1)}</div>
        </div>
    );
};

export default ThirdDegreeTabPanel;
