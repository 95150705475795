import "./HistoryTab.css";

const HistoryTab = ({ collection, name, number }) => {
    return (
        <div
            className="tab-pane fade text-div-margin show active" // show active is for the first tab element to show
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            <img
                className="history-image"
                alt="Portrait of young Joerg Huber"
                src="/images/portraitsJoerg/001.jpg"
            />
            <div className="copyright-text-div margin-bottom">
                <a
                    rel="noopener noreferrer"
                    href="https://rosswhitaker.com/"
                    target="_blank"
                >
                    © Photo by Ross Whitaker, Paris, France - 1990
                </a>
            </div>
            <div className="text-div margin-bottom">
                {collection[number].content.text1}
            </div>
            <img
                className="history-image"
                alt="Portrait of Joerg Huber Spain by Bodo Vitus"
                src="/images/portraitsJoerg/002.jpg"
            />
            <div className="copyright-text-div margin-bottom">
                <a
                    rel="noopener noreferrer"
                    href="http://www.bodovitus.de/"
                    target="_blank"
                >
                    © Photo by Bodo Vitus, San Pedro, Spain - 2011
                </a>
            </div>
            <div className="text-div margin-bottom">
                {collection[number].content.text2}
            </div>
            <img
                className="history-image"
                alt="Portrait of Joerg Huber now"
                src="/images/portraitsJoerg/003.jpg"
            />
            <div className="copyright-text-div margin-bottom">
                <a
                    rel="noopener noreferrer"
                    href="http://www.michaelwirth.com/"
                    target="_blank"
                >
                    © Photo by Michael Wirth - 2020
                </a>
            </div>
        </div>
    );
};

export default HistoryTab;
