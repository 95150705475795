import ConditionalButton from "../Buttons/ConditionalButton";
import ModalComponent from "../../ModalComponent/ModalComponent";

const SecondDegreeTabPanel = ({ collection, name, number }) => {
    let projectList = collection[number].content.map((project, index) => (
        <div key={index}>
            <ConditionalButton
                content={project}
                modalId={`${name}Modal${index}`}
            />
            <ModalComponent
                modalId={`${name}Modal${index}`}
                content={project}
                folderName={collection[number].folderName}
                depth={1}
            />
        </div>
    ));

    return (
        <div
            className="tab-pane fade carousel-img"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            <div>{projectList}</div>
        </div>
    );
};

export default SecondDegreeTabPanel;
