import { Component } from "react";
import Home from "./components/Home/Home";
import Main from "./components/Main/Main";
import Player from "./components/Music/Music";
import soundFile from "./audio/sirens.mp3";
import { BrowserRouter, Route } from "react-router-dom";

class App extends Component {
    render() {
        return (
            <>
                <Player url={soundFile}></Player>
                <BrowserRouter>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/main" component={Main} />
                </BrowserRouter>
            </>
        );
    }
}

export default App;
