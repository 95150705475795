import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
    const title = "joerg huber";

    return (
        <Link className="main-font outer-div fade-in btn-title" to="/main">
            <div className="inner-div">
                <div className="row">
                    <div className="col title">{title}</div>
                </div>
                <div className="row">
                    <div className="col btn-language">art of danger</div>
                </div>
            </div>
        </Link>
    );
}

export default Home;
