import "./Music.css";
import React, { useState, useEffect } from "react";
import pauseIcon from "../../assets/images/functional/redTarget.png";
import playIcon from "../../assets/images/functional/whiteTarget.png";

const useAudio = (url) => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);

	// needed for ios to prevent "Unhandled Rejection (NotAllowedError)"
	let audioPromise = audio.play();
	if (audioPromise !== undefined) {
		audioPromise.catch(() => {}).then(() => {});
	}

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [audio, playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		return () => {
			audio.removeEventListener("ended", () => setPlaying(false));
		};
	}, [audio]);

	return [playing, toggle];
};

const Player = ({ url }) => {
	const [playing, toggle] = useAudio(url);

	return (
		<div className="music-player">
			<button id="audio" className="btn audio-btn" onClick={toggle}>
				<img
					className="audio-img"
					alt=""
					src={playing ? pauseIcon : playIcon}
				></img>
				<div className="audio-text main-font">{playing ? "pause" : "play"}</div>
			</button>
		</div>
	);
};

export default Player;
