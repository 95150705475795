import "./TextTab.css";

const TextTab = ({ collection, name, number }) => {
    return (
        <div
            className="tab-pane fade text-div-margin"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles"> {collection[number].name}</div>
            <img
                className="contact-image"
                alt="Joerg Huber with his guardian"
                src="/images/lostVanishedMiscellaneous/001.jpg"
            />
            <div className="text-div">{collection[number].content.text}</div>
        </div>
    );
};

export default TextTab;
