const MultiLinkTab = ({ collection, name, number }) => {
    let linksList = collection[number].content[0].content.map(
        (links, index) => (
            <div className="main-font" key={index}>
                {/* 		Adding rel="noopener noreferrer" to links protects your site's users against having the site you've linked to potentially hijacking the browser (via rogue JS). */}
                <a rel="noopener noreferrer" href={links.href} target="_blank">
                    {links.title}
                </a>
            </div>
        )
    );

    return (
        <div
            className="tab-pane fade"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            <div>{linksList}</div>
        </div>
    );
};

export default MultiLinkTab;
