const MultiTextTab = ({ collection, name, number }) => {
    let projectList = collection[number].content[0].content.map(
        (project, index) => (
            <div key={index}>
                <button
                    data-toggle="modal"
                    data-target={`#${name}Modal${index}`}
                    className="btn main-font btn-modal-content"
                >
                    {project.title}
                </button>
                <div
                    className="modal fade"
                    id={`${name}Modal${index}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby={`${name}Modal${index}Label`}
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content carousel">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-div">
                                {project.text}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );

    return (
        <div
            className="tab-pane fade"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            <div>{projectList}</div>
        </div>
    );
};

export default MultiTextTab;
