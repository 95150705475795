import ReactPlayer from "react-player";
import { CarouselComponent } from "../CarouselComponent/CarouselComponent";

const ModalComponent = ({ modalId, content, folderName, depth }) => (
    <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${modalId}Label`}
        aria-hidden="true"
    >
        <div className="modal-dialog" role="document">
            <div className="modal-content carousel">
                <div className="modal-header">
                    <button
                        type="button"
                        className="close btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {content.type === "video" ? (
                        <ReactPlayer
                            className="react-player__video"
                            url={content.src}
                            controls
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: "nodownload",
                                    },
                                },
                            }}
                        />
                    ) : (
                        <CarouselComponent
                            collection={content}
                            rootImgFolder={folderName}
                            depth={depth}
                        />
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default ModalComponent;
