const BtnTabModal = ({ collection, name, number }) => {
    let modalId = `${name}Modal`;

    return (
        <a
            id={`v-pills-${name}-tab`}
            data-toggle="pill"
            href={`#v-pills-${name}`}
            role="tab"
            aria-controls={`v-pills-${name}`}
            className="nav-link btn-tab main-font"
            data-target={`#v-pills-${name}`}
            aria-selected="false"
        >
            <span data-toggle="modal" data-target={`#${modalId}`}>
                {collection[number].name}
            </span>
        </a>
    );
};
export default BtnTabModal;
