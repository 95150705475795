const BtnTab = ({ collection, name, number }) => {
    return (
        <a
            id={`v-pills-${name}-tab`}
            data-toggle="pill"
            href={`#v-pills-${name}`}
            role="tab"
            aria-controls={`v-pills-${name}`}
            className="nav-link btn-tab main-font"
            data-target={`#v-pills-${name}`}
            aria-selected="false"
        >
            {collection[number].name}
        </a>
    );
};

export default BtnTab;
