import "./BtnTabModalContent.css";
import { CarouselComponent } from "../../CarouselComponent/CarouselComponent";

const BtnTabModalContent = ({ collection, name, number }) => {
    let modalId = `${name}Modal`;

    return (
        <>
            <div
                className="tab-pane fade"
                id={`v-pills-${name}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${name}-tab`}
            >
                <div className="sub-titles">{collection[number].name}</div>
                <button
                    data-toggle="modal"
                    data-target={`#${modalId}`}
                    className="btn main-font btn-modal-content"
                >
                    {`Click here to browes through the collection again.`}
                </button>
                <div
                    className="modal fade"
                    id={modalId}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby={`${modalId}Label`}
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content carousel">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <CarouselComponent
                                    collection={collection[number]}
                                    rootImgFolder={
                                        collection[number].folderName
                                    }
                                    depth={0}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BtnTabModalContent;
