import "./CarouselComponent.css";
import React, { useState } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";

const generateImagePath = (depth, rootImgFolder, collection, item) => {
    switch (depth) {
        case 0:
            return `/images/${rootImgFolder}/${item.img}.jpg`;
        case 1:
            return `/images/${rootImgFolder}/${collection.folderName}/${item.img}.jpg`;
        case 2:
            return `/images/${rootImgFolder}/${collection.parentName}/${collection.folderName}/${item.img}.jpg`;
        default:
            console.log("Please define a depth in your CarouselComponent");
            return "";
    }
};

export const CarouselComponent = ({ collection, rootImgFolder, depth }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = collection.content;

    const next = () => {
        if (animating) return;
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const previous = () => {
        if (animating) return;
        setActiveIndex(
            (prevIndex) => (prevIndex - 1 + items.length) % items.length
        );
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item, index) => {
        const src = generateImagePath(depth, rootImgFolder, collection, item);

        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => {
                    setAnimating(false);
                }}
                key={index}
            >
                <img
                    src={src}
                    alt={item.title}
                    className="carousel-img filter"
                />
                <CarouselCaption
                    captionText={item.description}
                    captionHeader={item.title}
                />
            </CarouselItem>
        );
    });

    const shouldRenderControls = items.length > 1;

    return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            {slides}
            {shouldRenderControls && (
                <>
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </>
            )}
        </Carousel>
    );
};
