import { useState } from "react";
import ReactPlayer from "react-player";
import { CarouselComponent } from "../../CarouselComponent/CarouselComponent";

import "./MixTab.css";

// videos and carousel
const MixTab = ({ collection, name, number }) => {
    const [videoStates, setVideoStates] = useState({});

    const handleShow = (index) => {
        setVideoStates((prevStates) => ({
            ...prevStates,
            [index]: true,
        }));
    };

    const handleHide = (index) => {
        setVideoStates((prevStates) => ({
            ...prevStates,
            [index]: false,
        }));
    };

    let projectList = collection[number].content.map((project, index) => (
        <div key={index}>
            <button
                data-toggle="modal"
                data-target={`#${name}Modal${index}`}
                className="btn main-font btn-modal-content title-text-div"
                onClick={() => handleShow(index)}
            >
                {project.title}
            </button>
            <div
                className="modal fade"
                id={`${name}Modal${index}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby={`${name}Modal${index}Label`}
                aria-hidden="true"
                onClick={() => handleHide(index)}
                onKeyDown={(event) => {
                    if (event.key === "Escape") {
                        handleHide(index);
                    }
                }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content carousel">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleHide(index)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {project.type === "video" ? (
                            <ReactPlayer
                                className="react-player__video"
                                url={project.src}
                                playing={videoStates[index] || false}
                                controls
                                onClick={(event) => event.stopPropagation()}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: "nodownload",
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <div>
                                <CarouselComponent
                                    collection={project}
                                    rootImgFolder={
                                        collection[number].folderName
                                    }
                                    depth={1}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ));
    return (
        <div
            className="tab-pane fade"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            {<div>{projectList}</div>}
        </div>
    );
};

export default MixTab;
