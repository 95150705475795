import "./Main.css";
import { Link } from "react-router-dom";
import collectionsEng from "../../collections/collectionsEng";
import BtnTabModalContent from "../Sidebar/Tabs/BtnTabModalContent";
import BtnTab from "../Sidebar/Buttons/BtnTab";
import BtnTabModal from "../Sidebar/Buttons/BtnTabModal";
import SecondDegreeTabPanel from "../Sidebar/Tabs/SecondDegreeTabPanel";
import ThirdDegreeTabPanel from "../Sidebar/Tabs/ThirdDegreeTabPanel";
import HistoryTab from "../Sidebar/Tabs/HistoryTab";
import MixTab from "../Sidebar/Tabs/MixTab";
import TextTab from "../Sidebar/Tabs/TextTab";
import TextAndPanelTab from "../Sidebar/Tabs/TextAndPanelTab";
import MultiTextTab from "../Sidebar/Tabs/MultiTextTab";
import MultiLinkTab from "../Sidebar/Tabs/MultiLinkTab";

const Main = () => {
    const currentCollection = collectionsEng;

    // Tabs
    const tabOne = { name: "first", number: 0 };
    const tabTwo = { name: "second", number: 1 };
    const tabThree = { name: "third", number: 2 };
    const tabFour = { name: "fourth", number: 3 };
    const tabFive = { name: "fifth", number: 4 };
    const tabSix = { name: "sixth", number: 5 };
    const tabSeven = { name: "seventh", number: 6 };
    const tabEight = { name: "eighth", number: 7 };
    const tabNine = { name: "ninth", number: 8 };
    const tabTen = { name: "tenth", number: 9 };
    const tabEleven = { name: "eleventh", number: 10 };
    const tabTwelve = { name: "twelfth", number: 11 };
    const tabThirteen = { name: "thirteenth", number: 12 };
    const tabFourteen = { name: "fourteenth", number: 13 };
    const tabFifteen = { name: "fifteen", number: 14 };

    return (
        <>
            {/* Message to turn the phone horizontally*/}
            {/* <div id="turn-message">Please rotate your device</div> */}
            <div className="d-flex align-items-start">
                <div
                    className="nav flex-column nav-pills tab-column-margin"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                >
                    <a
                        className="nav-link btn-tab main-font active"
                        id={`v-pills-${tabOne.name}-tab`}
                        data-toggle="pill"
                        data-target={`#v-pills-${tabOne.name}`}
                        role="tab"
                        aria-controls={`v-pills-${tabOne.name}`}
                        aria-selected="true"
                        href={`#v-pills-${tabOne.name}`}
                    >
                        {currentCollection[tabOne.number].name}
                    </a>
                    <BtnTabModal
                        collection={currentCollection}
                        name={tabTwo.name}
                        number={tabTwo.number}
                    />
                    <BtnTabModal
                        collection={currentCollection}
                        name={tabThree.name}
                        number={tabThree.number}
                    />
                    <BtnTabModal
                        collection={currentCollection}
                        name={tabFour.name}
                        number={tabFour.number}
                    />
                    <BtnTabModal
                        collection={currentCollection}
                        name={tabFive.name}
                        number={tabFive.number}
                    />
                    <BtnTabModal
                        collection={currentCollection}
                        name={tabSix.name}
                        number={tabSix.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabSeven.name}
                        number={tabSeven.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabEight.name}
                        number={tabEight.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabNine.name}
                        number={tabNine.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabTen.name}
                        number={tabTen.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabEleven.name}
                        number={tabEleven.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabTwelve.name}
                        number={tabTwelve.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabThirteen.name}
                        number={tabThirteen.number}
                    />
                    <BtnTab
                        collection={currentCollection}
                        name={tabFourteen.name}
                        number={tabFourteen.number}
                    />
                    <Link
                        to="/"
                        id={`v-pills-${tabFifteen.name}-tab`}
                        data-toggle="pill"
                        href={`#v-pills-${tabFifteen.name}`}
                        role="tab"
                        aria-controls={`v-pills-${tabFifteen.name}`}
                        className="nav-link btn-tab main-font"
                        aria-selected="false"
                    >
                        back to home
                    </Link>
                </div>
                {/* content of the above tabs */}
                <div
                    className="tab-content tab-content-margin"
                    id="v-pills-tabContent"
                >
                    <HistoryTab
                        collection={currentCollection}
                        name={tabOne.name}
                        number={tabOne.number}
                    />
                    <BtnTabModalContent
                        collection={currentCollection}
                        name={tabTwo.name}
                        number={tabTwo.number}
                    />
                    <BtnTabModalContent
                        collection={currentCollection}
                        name={tabThree.name}
                        number={tabThree.number}
                    />
                    <BtnTabModalContent
                        collection={currentCollection}
                        name={tabFour.name}
                        number={tabFour.number}
                    />
                    <BtnTabModalContent
                        collection={currentCollection}
                        name={tabFive.name}
                        number={tabFive.number}
                    />
                    <BtnTabModalContent
                        collection={currentCollection}
                        name={tabSix.name}
                        number={tabSix.number}
                    />
                    <SecondDegreeTabPanel
                        collection={currentCollection}
                        name={tabSeven.name}
                        number={tabSeven.number}
                    />
                    <SecondDegreeTabPanel
                        collection={currentCollection}
                        name={tabEight.name}
                        number={tabEight.number}
                    />
                    <MixTab
                        collection={currentCollection}
                        name={tabNine.name}
                        number={tabNine.number}
                    />
                    <TextAndPanelTab
                        collection={currentCollection}
                        name={tabTen.name}
                        number={tabTen.number}
                    />
                    <ThirdDegreeTabPanel
                        collection={currentCollection}
                        name={tabEleven.name}
                        number={tabEleven.number}
                    />
                    <MultiTextTab
                        collection={currentCollection}
                        name={tabTwelve.name}
                        number={tabTwelve.number}
                    />
                    <TextTab
                        collection={currentCollection}
                        name={tabThirteen.name}
                        number={tabThirteen.number}
                    />
                    <MultiLinkTab
                        collection={currentCollection}
                        name={tabFourteen.name}
                        number={tabFourteen.number}
                    />
                </div>
            </div>
        </>
    );
};

export default Main;
