import ModalComponent from "../../ModalComponent/ModalComponent";
import enterIcon from "../../../assets/images/functional/enterIcon.png";

const TextAndPanelTab = ({ collection, name, number }) => {
    let text = collection[number].description;
    return (
        <div
            className="tab-pane fade carousel-img"
            id={`v-pills-${name}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${name}-tab`}
        >
            <div className="sub-titles">{collection[number].name}</div>
            <div className="text-div">{text}</div>
            <button
                data-toggle="modal"
                data-target={`#${name}Modal`}
                className="main-font btn-modal-content btn-enter"
            >
                <img
                    src={enterIcon}
                    alt="home icon"
                    className="img-enter"
                ></img>
            </button>
            <ModalComponent
                modalId={`${name}Modal`}
                content={collection[number]}
                folderName={collection[number].folderName}
                depth={0}
            />
        </div>
    );
};

export default TextAndPanelTab;
