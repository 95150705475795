const ConditionalButton = ({ content, modalId }) => {
    const hasContent = content.content.length !== 0;

    return (
        <button
            data-toggle="modal"
            data-target={`#${modalId}`}
            className={`btn main-font btn-modal-content ${
                hasContent ? "" : "disabled-btn"
            }`}
            disabled={!hasContent}
        >
            {content.description}
        </button>
    );
};

export default ConditionalButton;
